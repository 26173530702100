import { Component } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router";

declare let gtag: Function;
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  title = 'inventia-angular';

  constructor(public router: Router){
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd){
        gtag('config', 'UA-151764271-2', {'page_path': event.urlAfterRedirects});
      }
    });
  }

  scrollTo(){
    $('html,body').animate({scrollTop: $('body').offset().top},'slow');
  }
}
