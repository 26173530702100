import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  { path: '',
    loadChildren: './modules/home-page/home-page.module#HomePageModule'
  },
  { path: 'about',
    loadChildren: './modules/about/about.module#AboutModule'
  },
  { path: 'business-development',
    loadChildren: './modules/business-development/business-development.module#BusinessDevelopmentModule'
  },
  { path: 'contact',
    loadChildren: './modules/contact/contact.module#ContactModule'
  },
  { path: 'human-resource',
    loadChildren: './modules/human-resource/human-resource.module#HumanResourceModule'
  },
  { path: 'injectables',
    loadChildren: './modules/injectables/injectables.module#InjectablesModule'
  },
  { path: 'investor',
    loadChildren: './modules/investor/investor.module#InvestorModule'
  },
  { path: 'manufacturing',
    loadChildren: './modules/manufacturing/manufacturing.module#ManufacturingModule'
  },
  { path: 'r-and-d',
    loadChildren: './modules/r-and-d/r-and-d.module#RAndDModule'
  },
  { path: 'product-finder',
    loadChildren: './modules/product-finder/product-finder.module#ProductFinderModule'
  },
  {
    path: 'download-attachments/:qrcode',
    loadChildren: './modules/qrcode-mail/qrcode-mail.module#QrcodeMailModule'
  },
  {
    path: 'qrcode-generator',
    loadChildren: './modules/qrcode-generator/qrcode-generator.module#QrcodeGeneratorModule'
  },
  {
    path: 'privacy-policy',
    loadChildren: './modules/privacy-policy/privacy-policy.module#PrivacyPolicyModule'
  },
  { path: 'terms-and-conditions',
    loadChildren: './modules/terms-and-conditions/terms-and-conditions.module#TermsAndConditionsModule'
  },
  {
    path: 'adverse-form',
    loadChildren: './modules/adverse-form/adverse-form.module#AdverseFormModule'
  },
  {
    path: 'maintenance',
    loadChildren: './modules/maintenance/maintenance.module#MaintenanceModule'
  },
  {
    path: '**',
    loadChildren: './modules/not-found/not-found.module#NotFoundModule'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
