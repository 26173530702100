import { Component, OnInit } from '@angular/core';
import { ServerConstants } from 'src/app/constants/server-constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  nutraLink: string;

  constructor() { }

  ngOnInit() {
    this.nutraLink = ServerConstants.NUTRA_URL;
  }

}
