import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Mail } from '../models/QRMail';
import { ServerConstants } from '../constants/server-constants';

@Injectable({
  providedIn: 'root'
})
export class QrcodeService {

  constructor(private http: HttpClient) { }

  mailAttachments(mail: Mail): Observable<any> {
    return this.http.post(ServerConstants.GET_API_QRCODE_MAIL, mail);
  }

  getAllParams() {
    return this.http.get(ServerConstants.GET_API_QRCODE_PARAM_LIST);
  }

}