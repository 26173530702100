import { Component, OnInit, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgwWowService } from 'ngx-wow';
import { Subscription }   from 'rxjs/Subscription';
import { filter } from 'rxjs/operators';
import 'rxjs/Rx';
import { ServerConstants } from 'src/app/constants/server-constants';
import { EventService } from '../../services/event.service';
import { Constants } from '../../constants/constants';

declare var $:any;

var c, currentScrollTop = 0;
var navbar;

var header;
var sticky;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  nutraLink: string;

  constructor(private wowService: NgwWowService, private router: Router, private event: EventService) {
    this.router.events.filter(event => event instanceof NavigationEnd).subscribe(event => {
      // Reload WoW animations when done navigating to page,
      // but you are free to call it whenever/wherever you like
      this.wowService.init(); 
      this.closeNav();
    });
  }

  ngOnInit() {
    header = document.getElementById("myHeader");
    sticky = header.offsetTop;
    this.nutraLink = ServerConstants.NUTRA_URL
  }

  // Overlay Menu
  openNav() {
    document.getElementById("myNav").style.height = "100%";
  }

  closeNav() {
    document.getElementById("myNav").style.height = "0%";
  }

  @HostListener('window:scroll')
  checkScroll() {

    navbar = $('#myHeader');
    var a = $(window).scrollTop();
    var b = navbar.height();
   
    currentScrollTop = a;
   
    if (c < currentScrollTop && a > b + b) {
      navbar.addClass("scrollUp");
      this.event.sendMessage({
        type: Constants.HEADER_SCROLL_UP,
        data: true
      });
    } else if (c > currentScrollTop && !(a <= b)) {
      navbar.removeClass("scrollUp");
      this.event.sendMessage({
        type: Constants.HEADER_SCROLL_UP,
        data: false
      });
    }
    c = currentScrollTop;

    if (window.pageYOffset > sticky) {
      header.classList.add("sticky");
    } else {
      header.classList.remove("sticky");
    }
  }
}
