import { environment } from '../../environments/environment';

export class ServerConstants {
  public static PRODUCT_LIST = environment.BaseUrl + "/products";
  public static THEROPATIC_PRODUCT_LIST = environment.BaseUrl + "/productstherapetic";
  public static FORMULATION_PRODUCT_LIST = environment.BaseUrl + "/productformulation";
  public static APIGRADE_PRODUCT_LIST = environment.BaseUrl + "/productapigrade";
  public static FORMULATION_SEARCH = environment.BaseUrl + "/searchformularion";
  public static GET_API_GRADE = environment.BaseUrl + "/apigrade";
  public static SEND_EMAIL = environment.BaseUrl + "/email";
  public static ATTACHMENTS_URL = environment.BaseUrl + "/download-attachments"
  public static GET_API_QRCODE_MAIL = environment.BaseUrl + "/qrcode-mail"
  public static GET_API_QRCODE_PARAM_LIST = environment.BaseUrl + "/qrcode-list"
  public static NUTRA_URL = 'https://www.nutriventia.com';
  public static SAVE_BUSINESS_FORM = environment.BaseUrl + "/bdemail";
  public static SAVE_HOME_EMAIL = environment.BaseUrl + "/emailhomepage";
  public static CONTACT_EMAIL = environment.BaseUrl + "/emailcontact";
  public static SAVE_ADVERSE_FORM = environment.BaseUrl + "/adverseEvent";
  public static SAVE_INJECTABLE_FORM = environment.BaseUrl + "/injectables";
  public static COUNTRY_LIST = environment.BaseUrl + "/adversecountry";
  public static REGION_LIST = environment.BaseUrl + "/region";

  /*Accept cookie*/
  public static ACCEPT_COOKIE = environment.BaseUrl + "/iptimestamp";
}
