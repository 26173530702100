export class Constants {
  public static THERAPEUTIC_CATEGORY = 1;
  public static FORMULATION = 2;
  public static AVAILABLE_GRADE = 3;

  public static ASSET_PATH = '/assets/';

  /*Custom Events*/
  public static HEADER_SCROLL_UP = 1;
}
